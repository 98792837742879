import React, {useMemo} from 'react';
import {ORDER_STATE} from '../Utils/OrderUtil';
import * as Ant from 'antd';

export const UI_STATE = {
  LIST: 'LIST',
  REVIEW: 'REVIEW',
  PRODUCTION: 'PRODUCTION',
};

const options = [
  {
    label: '全部',
    value: '',
  },
  ...Object.keys(ORDER_STATE).map((key) => ({
    label: ORDER_STATE[key],
    value: key,
  })),
];

export default function OrderStateFilter(props) {
  const {onChange, value, uiState} = props;

  const _options = useMemo(() => {
    switch (uiState) {
      case UI_STATE.PRODUCTION:
        return options
          .filter((option) =>
            [
              '',
              'production_waiting',
              'production_completed',
              'production_preparing',
              'production_in_production',
            ].includes(option.value),
          )
          .map((option) => {
            if (option.value === '') {
              return {
                label: '全部',
                value:
                  'production_waiting,production_preparing,production_in_production,production_completed',
              };
            }
            return option;
          });
      default:
        return options.filter(
          (option) => !['logistic_transit'].includes(option.value),
        );
    }
  }, [uiState]);

  return (
    <Ant.Row gutter={[10, 10]}>
      {_options.map((option, idx) => (
        <Ant.Col key={idx}>
          <Ant.Button
            type={option.value === value ? 'primary' : 'default'}
            key={idx}
            onClick={() => onChange(option.value)}>
            {option.label}
          </Ant.Button>
        </Ant.Col>
      ))}
    </Ant.Row>
  );
}
